<template>
  <b-card>
    <b-row>
      <b-col cols="10">
        <h3 class="mb-4">Wrestler Information</h3>
      </b-col>
      <b-col cols="2" v-if="allowDelete">
        <b-button class="btn" @click="deleteWrestler">
          <font-awesome-icon
            :icon="['fas', 'trash-alt']"
            class="text-primary"
          ></font-awesome-icon>
        </b-button>
      </b-col>
    </b-row>

    <b-form>
      <b-form-row>
        <b-col lg="6" cols="12" order="1">
          <b-form-group
            id="w-first-name-group"
            label="First Name:"
            :invalid-feedback="errorMessage('fname')"
            :state="validationState('fname')"
            label-for="w-first-name"
          >
            <template v-slot:label>
              First Name <span class="text-primary">*</span>
            </template>
            <b-form-input
              id="w-first-name"
              v-model.trim="wrestler.fname"
              type="text"
              placeholder="Wrestler's first name"
              :state="validationState('fname')"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col lg="6" cols="12" order="2">
          <b-form-group
            id="w-last-name-group"
            label="Last Name:"
            :invalid-feedback="errorMessage('lname')"
            :state="validationState('lname')"
            label-for="w-last-name"
          >
            <template v-slot:label>
              Last Name <span class="text-primary">*</span>
            </template>
            <b-form-input
              id="w-last-name"
              v-model.trim="wrestler.lname"
              type="text"
              placeholder="Wrestler's last name"
              :state="validationState('lname')"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col lg="6" cols="12" order="3">
          <b-form-group
            id="w-dob-group"
            label="Date of Birth:"
            :invalid-feedback="errorMessage('dob')"
            :state="validationState('dob')"
            label-for="w-dob"
          >
            <template v-slot:label>
              Date of Birth <span class="text-primary">*</span>
            </template>
            <b-form-input
              id="w-dob"
              v-model.trim="wrestler.dob"
              type="date"
              placeholder="Wrestler's Date of Birth"
              :state="validationState('dob')"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col lg="6" cols="12" order="4">
          <b-form-group
            id="w-sex-group"
            label="Gender:"
            :invalid-feedback="errorMessage('sex')"
            :state="validationState('sex')"
            label-for="w-sex"
          >
            <template v-slot:label>
              Gender <span class="text-primary">*</span>
            </template>
            <b-form-select
              id="w-sex"
              :options="sexOptions"
              v-model="wrestler.sex"
              :state="validationState('sex')"
            >
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col lg="6" cols="12" order="5">
          <b-form-group
            id="w-grade-group"
            label="Grade:"
            :invalid-feedback="errorMessage('grade')"
            :state="validationState('grade')"
            label-for="w-grade"
          >
            <template v-slot:label>
              Grade <span class="text-primary">*</span>
            </template>
            <b-form-select
              id="w-grade"
              :options="gradeOptions"
              v-model="wrestler.grade"
              :state="validationState('grade')"
            >
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col lg="6" cols="12" order="6">
          <b-form-group
            id="w-height-group"
            label="Height:"
            :invalid-feedback="errorMessage('height')"
            :state="validationState('height')"
            label-for="w-height"
          >
            <template v-slot:label>
              Height <span class="text-primary">*</span>
            </template>
            <b-form-select
              id="w-height"
              :options="heightOptions"
              v-model="wrestler.height"
              :state="validationState('height')"
            >
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col lg="6" cols="12" order="7">
          <b-form-group
            id="w-weight-group"
            label="Weight (lbs):"
            :invalid-feedback="errorMessage('weight')"
            :state="validationState('weight')"
            label-for="w-weight"
          >
            <template v-slot:label>
              Weight <span class="text-primary">*</span>
            </template>
            <b-form-input
              id="w-weight"
              type="text"
              v-model.number.trim="wrestler.weight"
              :state="validationState('weight')"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-form-row>
      <!--      <b-form-row>-->
      <!--        <b-col lg="6" cols="12" order="8">-->
      <!--          <b-form-group-->
      <!--            id="w-usa-wrestling-num-group"-->
      <!--            label="USA Wrestling #:"-->
      <!--            :invalid-feedback="errorMessage('usaId')"-->
      <!--            :state="validationState('usaId')"-->
      <!--            label-for="w-usa-wrestling-num"-->
      <!--          >-->
      <!--            <template v-slot:label> USA Wrestling # </template>-->
      <!--            <b-form-input-->
      <!--              id="w-usa-wrestling-num"-->
      <!--              v-model.trim="wrestler.usaId"-->
      <!--              type="text"-->
      <!--              placeholder="USA Wrestling Number"-->
      <!--              :state="validationState('usaId')"-->
      <!--            ></b-form-input>-->
      <!--          </b-form-group>-->
      <!--        </b-col>-->
      <!--      </b-form-row>-->
    </b-form>
  </b-card>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, numeric, helpers, minLength } from '@vuelidate/validators'

const dob = helpers.regex(/^\d{4}-\d{2}-\d{2}$/)

export default {
  name: 'wrestlerForm',
  props: {
    allowDelete: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
  data() {
    let heights = [{ value: null, text: '' }]

    for (let inches = 24; inches < 75; inches++) {
      heights.push({
        value: inches,
        text: Math.floor(inches / 12) + "'" + (inches % 12) + '"',
      })
    }
    return {
      sexOptions: [
        { value: null, text: '' },
        { value: 'M', text: 'Male' },
        { value: 'F', text: 'Female' },
      ],
      gradeOptions: [
        { value: null, text: '' },
        { value: '0', text: 'Kindergarten' },
        { value: '1', text: '1st' },
        { value: '2', text: '2nd' },
        { value: '3', text: '3rd' },
        { value: '4', text: '4th' },
        { value: '5', text: '5th' },
        { value: '6', text: '6th' },
        { value: '7', text: '7th' },
        { value: '8', text: '8th' },
      ],
      heightOptions: heights,
      wrestler: {
        fname: null,
        lname: null,
        dob: null,
        sex: null,
        height: null,
        weight: null,
        grade: null,
        usaId: null,
      },
    }
  },
  validations() {
    return {
      wrestler: {
        fname: {
          required: helpers.withMessage('A value is required', required),
          minLength: helpers.withMessage(
            ({ $params }) =>
              `This field should be at least ${$params.min} characters long`,
            minLength(2)
          ),
        },
        lname: {
          required: helpers.withMessage('A value is required', required),
          minLength: helpers.withMessage(
            ({ $params }) =>
              `This field should be at least ${$params.min} characters long`,
            minLength(2)
          ),
        },
        dob: {
          required: helpers.withMessage('A value is required', required),
          dob: helpers.withMessage('Please enter a valid date of birth', dob),
        },
        sex: { required: helpers.withMessage('A value is required', required) },
        height: {
          required: helpers.withMessage('A value is required', required),
        },
        weight: {
          required: helpers.withMessage('A value is required', required),
          numeric,
        },
        grade: {
          required: helpers.withMessage('A value is required', required),
        },
        usaId: {},
      },
    }
  },
  methods: {
    deleteWrestler() {
      if (this.allowDelete) {
        this.$emit('deleteWrestler')
      }
    },
    validationState(member) {
      const field = this.v$.wrestler[member]

      if (field.$dirty) {
        return !field.$error
      } else {
        return null
      }
    },
    errorMessage(member) {
      const field = this.v$.wrestler[member]

      if (field.$dirty && field.$error) {
        return field.$errors[0].$message
      } else {
        return ''
      }
    },
  },
}
</script>

<style scoped></style>
