<template>
  <error-toast
    header="Server Error"
    message="Your Registration could not be processed! Please try again later"
  ></error-toast>
</template>

<script>
import ErrorToast from '@/components/toasts/errorToast'
export default {
  name: 'registrationErrorToast',
  components: { ErrorToast },
}
</script>

<style scoped></style>
