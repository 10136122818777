<template>
  <error-toast :header="header">
    <template v-slot:body>
      <div class="mb-1">
        The following {{ registrant }} {{ has }} already been registered:
        <span class="ml-3"></span>
      </div>
      <template v-for="(duplicate, index) in duplicates">
        <div :key="index" :id="'duplicate-' + index" class="mb-0 ml-3">
          {{ duplicate }}
        </div>
      </template>
      <div class="mt-1">
        Conatct
        <a
          href="mailto:coaches@haverhillhammers.org"
          target="_blank"
          style="color: red; text-decoration: underline"
        >
          coaches@haverhillhammers.org</a
        >
        if this seems incorrect
      </div>
    </template>
  </error-toast>
</template>

<script>
import ErrorToast from '@/components/toasts/errorToast'
export default {
  name: 'duplicateRegistrantToast',
  components: { ErrorToast },
  props: {
    duplicates: {
      type: Array,
      required: true,
    },
  },
  computed: {
    header() {
      return this.duplicates.length > 1
        ? 'Duplicate Registrants'
        : 'Duplicate Registrant'
    },
    registrant() {
      return this.duplicates.length > 1 ? 'registrants' : 'registrant'
    },
    has() {
      return this.duplicates.length > 1 ? 'have' : 'has'
    },
  },
}
</script>

<style scoped></style>
