<template>
  <error-toast
    header="Registration is Closed"
    message="We are not taking new registrations at this time"
  ></error-toast>
</template>

<script>
import ErrorToast from '@/components/toasts/errorToast'

export default {
  name: 'registrationClosedToast',
  components: { ErrorToast },
}
</script>

<style scoped></style>
