<template>
  <div>
    <content-box
      v-if="showFreeTrial"
      title="Registration"
      class="col-md-7 col-xl-5"
    >
      <div class="mb-3">
        <h3 class="mb-4 text-center">
          Registration for the
          <span class="font-weight-bold">{{ season }}</span> season is now
          <span class="font-weight-bold">OPEN</span>!!
        </h3>
        <p>
          Haverhill Hammers youth wrestling is now registering kids for our
          {{ season }} season.
          <span class="font-weight-bold text-primary">October is FREE!</span>
          Open to all boys & girls, Kindergarten-8th grade, brand new and
          experienced wrestlers alike.
        </p>
        <p>
          Join a team that works hard and plays harder! The Haverhill Hammers
          youth wrestling program is dedicated to teaching the fundamentals of
          the sport of wrestling.
        </p>
        <p>
          Make new friends. Build confidence. Get some exercise in a structured,
          hard-working environment. And
          <span class="font-weight-bold text-primary">HAVE FUN!</span>
        </p>
        <p>
          Registration fees will be collected in November, if your child
          continues to wrestle in our program
        </p>
      </div>
    </content-box>

    <content-box v-else title="Registration" class="col-md-7 col-xl-5">
      <div class="mb-3">
        <h3 class="mb-4 text-center">
          Registration for the
          <span class="font-weight-bold">{{ season }}</span> season is now
          <span class="font-weight-bold">OPEN</span>!!
        </h3>
        <p>
          Haverhill Hammers youth wrestling is now registering kids for our
          {{ season }} season. Open to all boys & girls, Kindergarten-8th grade,
          brand new and experienced wrestlers alike.
        </p>
        <p>
          Join a team that works hard and plays harder! The Haverhill Hammers
          youth wrestling program is dedicated to teaching the fundamentals of
          the sport of wrestling.
        </p>
        <p>
          Make new friends. Build confidence. Get some exercise in a structured,
          hard-working environment. And
          <span class="font-weight-bold text-primary">HAVE FUN!</span>
        </p>
      </div>
    </content-box>

    <content-box class="col-md-7 col-xl-5">
      <b-row class="mb-3">
        <b-col lg="5" order="1" cols="12">
          <p>
            Our registrations fees are as follows<sup class="text-primary small"
              >1</sup
            >:
          </p>
        </b-col>
        <b-col lg="4" order="2" cols="12">
          <b-list-group>
            <b-list-group-item>
              <b-row>
                <b-col cols="8"> 1 Wrestler:</b-col>
                <b-col>
                  <span class="text-primary">$150</span>
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item>
              <b-row>
                <b-col cols="8"> 2 Wrestlers:</b-col>
                <b-col>
                  <span class="text-primary">$250</span>
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item>
              <b-row>
                <b-col cols="8"> 3+ Wrestlers:</b-col>
                <b-col>
                  <span class="text-primary">$325</span>
                </b-col>
              </b-row>
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
      <p>
        Your registration fee will cover the cost of practices, warm-ups,
        singlet, scrimmages & dual meets
        <sup class="text-primary small"> 2</sup>. Registration fees can be paid
        by cash, check or Venmo. More details will follow, once your
        registration is complete.
      </p>
      <p class="small mt-5">
        <sup class="text-primary">1</sup> If you have a financial hardship,
        registration fees may be reduced or waived. Contact
        <a
          href="mailto:coaches@haverhillhammersyouthwrestling.org"
          target="_blank"
        >
          coaches@haverhillhammersyouthwrestling.org
        </a>
        to dsicuss.
      </p>
      <p class="small">
        <sup class="text-primary">2</sup> Singlets are the property of the
        Haverhill Hammers and shall be returned at the end of the season.
        Failure to do so will result in additional fees.
      </p>

      <b-overlay :show="registering">
        <div class="mb-3">
          <b-card bg-variant="dark">
            <template v-for="(parentForm, index) in parentForms">
              <div
                :key="index"
                class="mb-2"
                :ref="'parentFormContainer-' + index"
              >
                <component
                  :is="parentForm"
                  ref="parentForms"
                  :accountPrimary="index === 0"
                  :allowChangePrimaryAccount="true"
                  @changePrimaryAccount="changePrimaryAccount(index)"
                  :allowDelete="index !== 0"
                  @deleteParent="deleteParent(index)"
                />
              </div>
            </template>
            <b-row class="justify-content-end">
              <b-col lg="5" cols="12" align="right">
                <b-button
                  class="btn btn-secondary btn-outline-primary mt-3 w-100"
                  @click="addParent"
                >
                  <span class="mr-3">Add Another Parent/Guardian</span>
                  <font-awesome-icon :icon="['fas', 'user-plus']" />
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </div>

        <div>
          <b-card bg-variant="dark">
            <template v-for="(wrestlerForm, index) in wrestlerForms">
              <div
                :key="index"
                class="mb-2"
                :ref="'wrestlerFormContainer-' + index"
              >
                <component
                  :is="wrestlerForm"
                  ref="wrestlerForms"
                  :allowDelete="index !== 0"
                  @deleteWrestler="deleteWrestler(index)"
                />
              </div>
            </template>
            <b-row class="row justify-content-end">
              <b-col lg="5" cols="12" align="right">
                <b-button
                  class="btn btn-secondary btn-outline-primary mt-3 w-100"
                  @click="addWrestler"
                >
                  <span class="mr-3">Add Another Wrestler</span>
                  <font-awesome-icon :icon="['fas', 'user-plus']" />
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </div>
      </b-overlay>
      <div class="mt-5 row justify-content-center">
        <b-button
          class="btn btn-secondary btn-outline-primary col-11"
          @click="register"
          :disabled="registering"
        >
          Submit Registration
        </b-button>
      </div>
    </content-box>
  </div>
</template>

<script>
import seasonFunctions from '@/commons/seasonFunctions'
import regFunctions from '@/commons/registrationFunctions'

import useVuelidate from '@vuelidate/core'

import invalidFormToast from '@/components/toasts/forms/invalidFormToast'
import duplicateRegistrantToast from '@/components/toasts/registration/duplicateRegistrantToast'
import registrationClosedToast from '@/components/toasts/registration/registrationClosedToast'
import registrationErrorToast from '@/components/toasts/registration/registrationErrorToast'

import apiService from '@/services/apiService'

import contentBox from '../../components/contentBox'
import parentForm from '@/components/registration/parentForm'
import wrestlerForm from '@/components/registration/wrestlerForm'
import router from '@/router'

export default {
  name: 'registration',
  components: {
    contentBox,
    parentForm,
    wrestlerForm,
    invalidFormToast,
    registrationClosedToast,
    registrationErrorToast,
    duplicateRegistrantToast,
  },
  setup() {
    // this will collect all nested component’s validation results
    const v = useVuelidate()

    return { v }
  },
  data() {
    return {
      parentForms: ['parentForm'],
      wrestlerForms: ['wrestlerForm'],
      registering: false,
      toast: false,
    }
  },
  mounted() {
    this.$on('deleteParent', this.deleteParent)
    this.$on('changePrimaryAccount', this.changePrimaryAccount)
    this.$on('deleteWrestler', this.deleteWrestler)
  },
  computed: {
    season() {
      return seasonFunctions.toString()
    },
    showFreeTrial() {
      return regFunctions.isFreeTrialOpen()
    },
  },
  methods: {
    addParent() {
      // push the new parent form onto the list
      const length = this.parentForms.push('parentForm')

      // wait for the ui to be re-rendered before trying to scroll to the div
      // and putting focus on the first element
      this.$nextTick(function () {
        const el = this.$refs['parentFormContainer-' + (length - 1)][0]

        window.scrollBy({
          top: el.getBoundingClientRect().top,
          behavior: 'smooth',
        })
      })
    },
    deleteParent(index) {
      this.parentForms.splice(index, 1)
    },
    changePrimaryAccount(index) {
      //if the user has set a new primary account, make sure that the other accounts are secondary accounts
      if (this.$refs.parentForms[index].parent.primary === true) {
        this.$refs.parentForms.forEach((p, i) => {
          if (index !== i) {
            p.parent.primary = false
          }
        })

        this.$refs.parentForms[index].parent.manager = false
      } else {
        //if the user is attempting to remove the last primary account, prevent them from doing so
        const hasPrimaryAccount = this.$refs.parentForms
          .map((p) => p.parent.primary)
          .reduce((previous, current) => previous || current)

        if (!hasPrimaryAccount) {
          this.$refs.parentForms[index].parent.primary = true
        }
      }
    },
    addWrestler() {
      // push the new parent form onto the list
      const length = this.wrestlerForms.push('wrestlerForm')

      // wait for the ui to be re-rendered before trying to scroll to the div
      // and putting focus on the first element
      this.$nextTick(function () {
        const el = this.$refs['wrestlerFormContainer-' + (length - 1)][0]

        window.scrollBy({
          top: el.getBoundingClientRect().top,
          behavior: 'smooth',
        })
      })
    },
    deleteWrestler(index) {
      this.wrestlerForms.splice(index, 1)
    },
    validatePrimaryAccount() {
      //make sure a primary account has been designated, and at that, make sure
      //there is only one account designated as the primary
      //
      //the comparison in reduce is XOR
      return this.$refs.parentForms
        .map((p) => p.parent.primary)
        .reduce((previous, current) => (previous ? !current : current))
    },
    register() {
      // validate the parent and wrestler forms
      this.v.$touch()

      if (!this.v.$error) {
        if (this.validatePrimaryAccount()) {
          this.registering = true

          let parents = []
          this.$refs.parentForms.map((p) =>
            parents.push({
              fname: p.parent.fname,
              lname: p.parent.lname,
              email: p.parent.email,
              home: p.parent.home,
              mobile: p.parent.mobile,
              venmo: p.parent.venmo,
              primary: p.parent.primary,
              manager: p.parent.manager,
            })
          )
          let wrestlers = []
          this.$refs.wrestlerForms.map((w) =>
            wrestlers.push({
              fname: w.wrestler.fname,
              lname: w.wrestler.lname,
              dob: w.wrestler.dob,
              sex: w.wrestler.sex,
              height: w.wrestler.height,
              weight: w.wrestler.weight,
              grade: w.wrestler.grade,
              usaId: w.wrestler.usaId,
            })
          )

          let registration = {
            parents: parents,
            wrestlers: wrestlers,
          }

          apiService
            .doRegistration(JSON.stringify(registration))
            .then((registered) => {
              // we need to redirect the user to the 'congratulations' page

              router.push({
                name: 'registered',
                params: {
                  season: this.season,
                  wrestlers: registered.wrestlers,
                },
              })
            })
            .catch((error) => {
              switch (error['error']) {
                //registration is closed!  how did you get here?
                case 1000:
                  this.$toast.error(registrationClosedToast)
                  break

                //trying to register a wrestler that has already been registered
                case 1001:
                  //get the list of offending users
                  let duplicates = error['duplicates']
                  this.$toast.error(
                    {
                      component: duplicateRegistrantToast,
                      props: {
                        duplicates: duplicates,
                      },
                    },
                    {
                      timeout: 10000,
                    }
                  )
                  break

                //who knows! something went wrong
                default:
                  this.$toast.error(registrationErrorToast)
              }
            })
            .finally(() => {
              this.registering = false
            })
        }
      } else {
        this.$toast.error(invalidFormToast)
      }
    },
  },
}
</script>

<style scoped></style>
