<template>
  <div class="container">
    <slot name="header">
      <h4 class="font-weight-bold">{{ header }}</h4>
      <hr />
    </slot>

    <slot name="body">
      <div>{{ message }}</div>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'errorToast',
  props: {
    header: {
      type: String,
      required: false,
    },
    message: {
      type: String,
      required: false,
    },
  },
}
</script>

<style scoped></style>
