<template>
  <error-toast
    header="Invalid Form"
    message="Please fix all form errors to continue"
  ></error-toast>
</template>

<script>
import errorToast from '@/components/toasts/errorToast'

export default {
  name: 'invalidFormToast',
  components: {
    errorToast,
  },
}
</script>

<style scoped></style>
