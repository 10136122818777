<template>
  <b-card>
    <b-row>
      <b-col cols="10">
        <h3 class="mb-4">Parent/Guardian Information</h3>
      </b-col>
      <b-col cols="2" v-if="allowDelete" align="right">
        <b-button class="btn" @click="deleteParent">
          <font-awesome-icon
              :icon="['fas', 'trash-alt']"
              class="text-primary"
          ></font-awesome-icon>
        </b-button>
      </b-col>
    </b-row>

    <b-form>
      <b-form-row>
        <b-col lg="6" cols="12" order="1">
          <b-form-group
              id="p-first-name-group"
              label="First Name:"
              :invalid-feedback="errorMessage('fname')"
              :state="validationState('fname')"
              label-for="p-first-name"
          >
            <template v-slot:label>
              First Name <span class="text-primary">*</span>
            </template>
            <b-form-input
                id="p-first-name"
                v-model.trim="parent.fname"
                type="text"
                placeholder="Parent/guardian's first name"
                :state="validationState('fname')"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col lg="6" cols="12" order="2">
          <b-form-group
              id="p-last-name-group"
              label="Last Name:"
              :invalid-feedback="errorMessage('lname')"
              :state="validationState('lname')"
              label-for="p-last-name"
          >
            <template v-slot:label>
              Last Name <span class="text-primary">*</span>
            </template>
            <b-form-input
                id="p-last-name"
                v-model.trim="parent.lname"
                type="text"
                placeholder="Parent/guardian's last name"
                :state="validationState('lname')"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col cols="12" order="3">
          <b-form-group
              id="p-email-group"
              label="Email address:"
              :invalid-feedback="errorMessage('email')"
              :state="validationState('email')"
              label-for="p-email"
          >
            <template v-slot:label>
              Email <span class="text-primary">*</span>
            </template>
            <b-form-input
                id="p-email"
                v-model.trim="parent.email"
                type="email"
                placeholder="Parent/guardian's email"
                :state="validationState('email')"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col lg="6" cols="12" order="4">
          <b-form-group
              id="p-mobile-ph-group"
              label="Mobile Phone #:"
              :invalid-feedback="errorMessage('mobile')"
              :state="validationState('mobile')"
              label-for="p-mobile-ph"
          >
            <template v-slot:label>
              Mobile Phone # <span class="text-primary">*</span>
            </template>
            <vue-phone-number-input
                id="p-mobile-ph"
                v-model.trim="parent.mobile"
                :error="v$.parent.mobile.$dirty ? v$.parent.mobile.$error : false"
                :only-countries="['US']"
                :no-country-selector="true"
                :no-example="true"
            />
          </b-form-group>
        </b-col>
        <b-col lg="6" cols="12" order="5">
          <b-form-group
              id="p-home-ph-group"
              label="Home Phone #:"
              label-for="p-home-ph"
          >
            <template v-slot:label> Home Phone #</template>
            <vue-phone-number-input
                id="p-home-ph"
                v-model.trim="parent.home"
                :only-countries="['US']"
                :no-country-selector="true"
                :no-example="true"
            />
          </b-form-group>
        </b-col>
      </b-form-row>
      <!--      <b-form-row>-->
      <!--        <b-col lg="6" cols="12" order="6">-->
      <!--          <b-form-group-->
      <!--            id="p-venmo-group"-->
      <!--            label="Venmo Username:"-->
      <!--            :invalid-feedback="errorMessage('venmo')"-->
      <!--            :state="validationState('venmo')"-->
      <!--            label-for="p-venmo"-->
      <!--          >-->
      <!--            <template v-slot:label> Venmo Username </template>-->
      <!--            <b-form-input-->
      <!--              id="p-venmo"-->
      <!--              v-model.trim="parent.venmo"-->
      <!--              type="text"-->
      <!--              placeholder="@venmo-user"-->
      <!--              :state="validationState('venmo')"-->
      <!--            ></b-form-input>-->
      <!--          </b-form-group>-->
      <!--        </b-col>-->
      <!--        <b-col-->
      <!--          v-if="showAccountManagement"-->
      <!--          cols="3"-->
      <!--          offset="9"-->
      <!--          offset-lg="3"-->
      <!--          order="7"-->
      <!--          align-self="center"-->
      <!--        >-->
      <!--          <b-row>-->
      <!--            <b-col cols="12">-->
      <!--              <b-form-checkbox-->
      <!--                v-model="parent.primary"-->
      <!--                :value="true"-->
      <!--                :unchecked-value="false"-->
      <!--                @change="changePrimaryAccount"-->
      <!--              >-->
      <!--                Primary Account-->
      <!--              </b-form-checkbox>-->
      <!--            </b-col>-->
      <!--          </b-row>-->
      <!--          <b-row>-->
      <!--            <b-col cols="12">-->
      <!--              <b-form-checkbox-->
      <!--                v-model="parent.manager"-->
      <!--                :value="true"-->
      <!--                :unchecked-value="false"-->
      <!--                :disabled="parent.primary"-->
      <!--                @change="changePrimaryAccount"-->
      <!--              >-->
      <!--                Account Manager-->
      <!--              </b-form-checkbox>-->
      <!--            </b-col>-->
      <!--          </b-row>-->
      <!--        </b-col>-->
      <!--      </b-form-row>-->
    </b-form>
  </b-card>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import {required, email, minLength, helpers} from '@vuelidate/validators'

const phNumber = helpers.regex(/^\(\d{3}\)\s?\d{3}-\d{4}$/)
const venmoHandle = helpers.regex(/^@/)

export default {
  name: 'parentForm',
  props: {
    allowDelete: {
      type: Boolean,
      required: false,
      default: false,
    },
    showAccountManagement: {
      type: Boolean,
      required: false,
      default: false,
    },
    accountPrimary: {
      type: Boolean,
      required: false,
      default: false,
    },
    allowChangePrimaryAccount: {
      type: Boolean,
      required: false,
      default: false,
    },
    accountManager: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
  data() {
    return {
      parent: {
        fname: null,
        lname: null,
        email: null,
        mobile: null,
        home: null,
        venmo: null,
        primary: this.accountPrimary,
        manager: this.accountPrimary ? false : this.accountManager,
      },
    }
  },
  validations() {
    return {
      parent: {
        fname: {
          required: helpers.withMessage('A value is required', required),
          minLength: helpers.withMessage(
              ({$params}) =>
                  `This field should be at least ${$params.min} characters long`,
              minLength(2)
          ),
        },
        lname: {
          required: helpers.withMessage('A value is required', required),
          minLength: helpers.withMessage(
              ({$params}) =>
                  `This field should be at least ${$params.min} characters long`,
              minLength(2)
          ),
        },
        email: {
          required: helpers.withMessage('A value is required', required),
          email,
        },
        mobile: {
          required: helpers.withMessage('A value is required', required),
          phNumber: helpers.withMessage(
              'Please enter a valid phone number',
              phNumber
          ),
        },
        home: {
          phNumber: helpers.withMessage(
              'Please enter a valid phone number',
              phNumber
          ),
        },
        venmo: {
          venmoHandle: helpers.withMessage(
              "Please make sure that your Venmo username start with a '@'",
              venmoHandle
          ),
        },
      },
    }
  },
  methods: {
    deleteParent() {
      if (this.allowDelete) {
        this.$emit('deleteParent')
      }
    },
    changePrimaryAccount() {
      if (this.allowChangePrimaryAccount) {
        this.$emit('changePrimaryAccount')
      }
    },
    validationState(member) {
      const field = this.v$.parent[member]

      if (field.$dirty) {
        return !field.$error
      } else {
        return null
      }
    },
    errorMessage(member) {
      const field = this.v$.parent[member]

      if (field.$dirty && field.$error) {
        return field.$errors[0].$message
      } else {
        return ''
      }
    },
  },
}
</script>

<style scoped></style>
